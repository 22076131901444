<template>
  <b-card class="p-1">
    <validation-observer ref="changeOperationForm">
    <b-form @submit="onSubmit" v-if="form">
      
      <!-- Email -->
      <b-form-group label="Email" description="Email de destinatario">
        <validation-provider
          #default="{ errors }"
          name="Email"
          rules="required|email"
        >
          <b-form-input
            size="sm"
            type="text"
            label="text"
            :disabled="formDisabled"
            v-model="form.recipient_email"
            name="recipient_email"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- Nombre -->
      <b-form-group label="Nombre" description="Nombre de destinatario">
        <validation-provider
          #default="{ errors }"
          name="Nombre"
          rules="required"
        >
          <b-form-input
            size="sm"
            type="text"
            label="text"
            :disabled="formDisabled"
            v-model="form.recipient_name"
            name="recipient_name"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- Apellido -->
      <b-form-group label="Apellido" description="Apellido de destinatario">
        <validation-provider
          #default="{ errors }"
          name="Apellido"
          rules="required"
        >
          <b-form-input
            size="sm"
            type="text"
            label="text"
            :disabled="formDisabled"
            v-model="form.recipient_last_name"
            name="recipient_last_name"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- Telefono -->
      <b-form-group label="Telefono" description="Telefono de destinatario">
        <validation-provider
          #default="{ errors }"
          name="Telefono"
          rules="required"
        >
          <b-form-input
            size="sm"
            type="text"
            label="text"
            :disabled="formDisabled"
            v-model="form.recipient_phone"
            name="recipient_phone"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <h4 class="title my-1 pt-2">A DONDE LO VAMOS A BUSCAR ?</h4>

      <b-form-group label="Dirección" description="Dirección">
        <b-form-textarea
          v-model="collectionAddresses"
          :disabled="true"
        ></b-form-textarea>
      </b-form-group>

      <h4 class="title my-1 pt-2">A DONDE LO VAMOS A LLEVAR ?</h4>

      <b-form-group label="Dirección" description="Dirección">
        <b-form-textarea
          v-model="retirementAddresses"
          :disabled="true"
        ></b-form-textarea>
      </b-form-group>

      <h4 class="title my-1 pt-2">QUE VAMOS A LLEVAR ?</h4>

      <!-- Producto -->
      <b-form-group label="Producto" description="Descripcion del producto a enviar">
        <validation-provider
          #default="{ errors }"
          name="Producto"
          rules="required"
        >
          <b-form-input
            size="sm"
            type="text"
            :disabled="true"
            v-model="form.product"
            name="product"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      
      <!-- Peso -->
      <b-form-group label="Peso" description="Peso del producto a enviar">
        <validation-provider
          #default="{ errors }"
          name="Peso"
        >
          <b-form-input
            size="sm"
            type="number"
            label="text"
            :disabled="true"
            v-model="form.weight"
            name="weight"
            min="0"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
          <span class="help-input">Minimo 1 KG</span>
        </validation-provider>
      </b-form-group>

      <!-- Dimensiones -->
      <b-form-group label="Dimensiones" description="Dimensiones del producto a enviar">
        <validation-provider
          #default="{ errors }"
          name="Dimensiones"
          rules="required"
        >
          <b-form-input
            size="sm"
            type="text"
            maxlength="100"
            :disabled="true"
            v-model="form.dimensions"
            name="dimensions"
            placeholder="33x44x55"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      
      <!-- Nota -->
      <b-form-group label="Nota" description="¿Algo adicional que debamos saber?">
        <validation-provider
          #default="{ errors }"
          name="Nota"
        >
          <b-form-textarea
            size="sm"
            type="text"
            maxlength="700"
            :disabled="formDisabled"
            v-model="form.note"
            name="note"
            :state="errors.length > 0 ? false : null"
          ></b-form-textarea>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- Monto -->
      <b-form-group label="Monto" description="Monto a pagar por el envio">
        <validation-provider
          #default="{ errors }"
          name="Monto"
          rules="required"
        >
          <b-form-input
            size="sm"
            type="text"
            readonly
            :disabled="false"
            v-model="form.amount"
            name="amount"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <div class="row">
        <div class="col-12" v-if="!formDisabled">
          <b-button
            ref="submitButton"
            class="mr-1 float-right"
            variant="primary"
            type="submit"
          >
            <feather-icon
              size="15"
              icon="SaveIcon"
              class="mr-50"
            />
            <span style="font-size: 15px">
              Guardar
            </span>
          </b-button>
        </div>

        <div class="col-12" v-else>
          <b-alert show variant="info" v-if="user.type === 'pre-pago'">
            Para continuar con el proceso de envío, es necesario que la orden
            sea abonada.
          </b-alert>

          <b-button
            class="mr-1 float-right"
            variant="success"
            type="button"
            @click="onPay"
            v-if="user.type === 'pre-pago'"
          >
            <feather-icon
              size="15"
              icon="CreditCardIcon"
              class="mr-50"
            />
            <span style="font-size: 15px">
              Pagar
            </span>
          </b-button>

          <router-link
            class="btn btn-primary mr-1 float-right text-white"
            to="/dashboard/operations"
            style="background: #f68d1e; border-color: #f68d1e"
          >
            <feather-icon
              size="15"
              icon="AlignJustifyIcon"
              class="mr-50"
            />
            <span style="font-size: 15px">
              Operaciones
            </span>
          </router-link>
        </div>
      </div>
    </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import OperationService from "@/services/OperationService";
import AlertService from "@/services/AlertService";
import MercadoPagoService from "@/services/MercadoPagoService";
import { required, email } from "@validations";
import Swal from "sweetalert2";

export default {
  name: "OperationReturnChange",
  components:{
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      required, 
      email,
      user: this.$store.state.user,
      collectionAddresses: null,
      retirementAddresses: null,
      operation: null,
      form: null,
      formDisabled: false,
      isLoading: null,
      type: "devolution",
    };
  },

  methods: {
    /**
     *
     */
    onSubmit(event) {
      event.preventDefault();
      this.$refs.changeOperationForm.validate().then((valid) => {
        if (valid) {
          this.$refs.submitButton.disabled = true;
          OperationService.storeReturnChange(this.form)
            .then(({ data }) => {
              this.$refs.submitButton.disabled = false;
              this.formDisabled = true;
              this.item = data.data;
              Swal.fire({
                title:
                  this.user.type === "post-pago"
                    ? "Operación exitosa"
                    : "Para continuar con el proceso de envío, es necesario que la orden sea abonada.",
                customClass: AlertService.customClass,
              });
            })
            .catch((error) => {
              console.error(error);
              this.$refs.submitButton.disabled = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Agregar Nuevo`,
                  text: `Error al tratar de almacenar este elemento`,
                  icon: "AlertOctagonIcon",
                  variant: "danger",
                },
              });
            });
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Actualizar`,
              text: `El formulario contiene errores`,
              icon: "AlertOctagonIcon",
              variant: "danger",
            },
          });
        }
      });
    },

    /**
     *
     */
    onPay() {
      const transactions = [this.item.id];
      this.$swal.queue([
        {
          title: "Pagar operaciones",
          confirmButtonText: "Pagar",
          text:
            "¿Estas seguro de que quieres pagar la operacion(es)" +
            transactions +
            "?",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return MercadoPagoService.store({ transactions })
              .then((response) => {
                const { data } = response.data;
                this.$router.push("/dashboard/operations/pay/" + data.code);
              })
              .catch((error) => {
                console.error(error);
                this.$swal.insertQueueStep({
                  icon: "error",
                  title: "Error al intentar generar el pago",
                });
              });
          },
        },
      ]);
    },
    /**
     *
     */
    onClear() {
      this.item = null;
      this.formDisabled = false;
      this.form = {
        type: this.type,
      };
      window.location.reload();
    },

    /**
     *
     */
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
  },
  mounted() {
    this.isLoading = true;
    this.type = this.$route.query.type;
    OperationService.returnChange(this.$route.params.id, this.type)
      .then((response) => {
        const { data } = response;
        this.operation = data.data;
        this.form = {
          type: this.type,
          dimensions: this.operation.transaction.dimensions,
          notes: this.operation.transaction.notes,
          product: this.operation.transaction.product,
          recipient_email: this.operation.transaction.recipient_email,
          recipient_last_name: this.operation.transaction.recipient_last_name,
          recipient_name: this.operation.transaction.recipient_name,
          recipient_phone: this.operation.transaction.recipient_phone,
          transaction_id: this.operation.transaction.transaction_id,
          customer_id: this.operation.transaction.customer_id,
          weight: this.operation.transaction.weight,
          amount: this.operation.price.amount,
        };

        this.collectionAddresses =
          [
            this.operation.transaction.collection_addresses.province,
            this.operation.transaction.collection_addresses.location,
            this.operation.transaction.collection_addresses.street,
            this.operation.transaction.collection_addresses.height,
            this.operation.transaction.collection_addresses.floor,
            this.operation.transaction.collection_addresses.departament,
          ]
            .filter((d) => d)
            .join(" ") +
          ". Codigo postal  (" +
          this.operation.transaction.collection_addresses.postal_code +
          ")";

        this.retirementAddresses =
          [
            this.operation.transaction.retirement_addresses.province,
            this.operation.transaction.retirement_addresses.location,
            this.operation.transaction.retirement_addresses.street,
            this.operation.transaction.retirement_addresses.height,
            this.operation.transaction.retirement_addresses.floor,
            this.operation.transaction.retirement_addresses.departament,
          ]
            .filter((d) => d)
            .join(" ") +
          ". Codigo postal (" +
          this.operation.transaction.retirement_addresses.postal_code +
          ")";
        this.isLoading = false;
      })
      .catch((error) => {
        this.isLoading = false;
        console.error(error);
      });
  },
};
</script> 