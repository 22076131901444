import axios from 'axios';

export default class MercadoPagoService {

  /**
   * 
   * @param {*} data 
   */
  static store(data) {
    return axios.post(process.env.VUE_APP_API + '/mercadopago/operations/to-pay', data);
  }


  /**
   * 
   * @param {*} data 
   */
   static storeCharge(data) {
    return axios.post(process.env.VUE_APP_API + '/mercadopago/operations/to-pay-charge', data);
  }

  /**
   * 
   * @param {*} id 
   * @param {*} data 
   */
  static show(id) {
    return axios.get(process.env.VUE_APP_API + '/mercadopago/' + id);
  }

  /**
   * 
   * @param {*} data 
   */
  static process(id, data) {
    return axios.post(process.env.VUE_APP_API + '/mercadopago/' + id, data);
  }

}
