import axios from 'axios';

export default class Operationservice {

  /**
   * @description login
   * @param { username | login, password } values
   */
  static index() {
    return axios.post(process.env.VUE_APP_API + '/transactions');
  }


  /**
   * @description store data
   * @param data { location_id, street, height,  postal_code } values
   * @param options { edit, duplicate }
   */
  static store(data, options) {
    if (data.id) {
      if (options.duplicate) {
        return axios.post(process.env.VUE_APP_API + '/transactions', data);
      } else {
        return axios.put(process.env.VUE_APP_API + '/transactions/' + data.id, data);
      }
    }
    return axios.post(process.env.VUE_APP_API + '/transactions', data);
  }


  /**
   * @description store data
   * @param data { reason} values
   */
  static cancel(id, data) {
    return axios.post(process.env.VUE_APP_API + `/transactions/${id}/cancel`, data);
  }


  /**
   * @description delete a element
   * @param { id } id point
   */
  static destroy({
    id
  }) {
    return axios.delete(process.env.VUE_APP_API + '/transactions/' + id);
  }

  /**
   * @description restore a element
   * @param { id } id point
   */
  static restore({
    id
  }) {
    return axios.get(process.env.VUE_APP_API + `/transactions/${id}/restore`);
  }

  /**
   * @description update a element
   * @param { id } id point
   * @param values { location_id, street, height,  postal_code } update values
   */
  static updated(id, values) {
    return axios.put(process.env.VUE_APP_API + '/transactions/' + id, values);
  }

  /**
   * @description get a element by id
   * @param { id } id point
   */
  static show(id) {
    return axios.get(process.env.VUE_APP_API + '/transactions/' + id);
  }

  /**
   * 
   */
  static statuses() {
    return axios.get(process.env.VUE_APP_API + '/transactions/statuses');
  }

  /**
   * @description store data
   * @param data { location_id, street, height,  postal_code } values
   */
  static returnChange(id, type) {
    const params = {
      type
    };
    return axios.get(process.env.VUE_APP_API + '/transactions/' + id + '/return-change', {
      params
    });
  }

  /**
   * 
   * @param {*} page 
   * @param {*} search 
   */
  static storeReturnChange(value) {
    return axios.post(process.env.VUE_APP_API + '/transactions/' + value.transaction_id + '/return-change', value);
  }

  /**
   * @description get customers
   * @param { page }  number page
   */
  static customers() {
    return axios.get(process.env.VUE_APP_API + '/transactions/customers');
  }


  /**
   * @description get customers
   * @param { page }  number page
   */
  static customer(id) {
    return axios.get(process.env.VUE_APP_API + '/transactions/customers/' + id);
  }


  /**
   * @description get customers
   * @param { page }  number page
   */
  static prices() {
    return axios.get(process.env.VUE_APP_API + '/transactions/prices');
  }

  /**
   * @description get points
   * @param { page }  number page
   */
  static deposits() {
    return axios.get(process.env.VUE_APP_API + '/storehouse');
  }

  /**
   * @description get points
   * @param { page }  number page
   */
  static points(params = {}) {
    return axios.get(process.env.VUE_APP_API + '/allpoints', {
      params
    });
  }

  /**
   * @description get points
   * @param { page }  number page
   */
  static getPostalCodeUser(customer_id) {

    return axios.get(process.env.VUE_APP_API + `/customers/${customer_id}/postal-code`);
  }

  /**
   * @description get addresses
   * @param { customer_id } customer id to load address
   */
  static addresses(customer_id) {
    const params = {
      customer_id: customer_id
    };
    return axios.get(process.env.VUE_APP_API + '/transactions/addresses', {
      params
    });
  }

  /**
   *
   */
  static collectionPending(user_id, transactions_id, date_from, date_to) {
    const data = {
      user_id,
      date_from,
      date_to,
      transactions_id
    };
    return axios.post(process.env.VUE_APP_API + '/closure-operational/collection-pending', data);
  }

  /**
   * @description get provinces
   */
  static provinces() {
    return axios.get(process.env.VUE_APP_API + '/transactions/provinces');
  }

  /**
   * @description update a element
   * @param { id } id point
   * @param values { location_id, street, height,  postal_code } update values
   */
  static payUp(data) {
    return axios.post(process.env.VUE_APP_API + '/closure-operational', data);
    // return axios.post(process.env.VUE_APP_API + '/transactions/pay-up', data);
  }

  static updatePayUp(data, id) {
    return axios.put(process.env.VUE_APP_API + '/closure-operational/' + id, data);
    // return axios.post(process.env.VUE_APP_API + '/transactions/pay-up', data);
  }

  /**
   * @description update a element
   * @param { id } id point
   * @param values { location_id, street, height,  postal_code } update values
   */
  static payBack(data) {
    return axios.post(process.env.VUE_APP_API + '/transactions/pay-back', data);
  }

  /**
   * 
   */
  static changeState(data, id) {
    return axios.post(process.env.VUE_APP_API + `/transactions/${id}/to-change-state`, data);
  }

  /**
   * @description search element
   * @param { id } tracking_deonics
   */
  static timeline_tracking(data) {
    return axios.post(process.env.VUE_APP_API + '/transactions/search_by_tracking', {
      tracking_id: data
    });
  }

  /**
   * @description get xml
   */
  static export(params = {}) {
    return axios.get(process.env.VUE_APP_API + '/transactions/export', {
      params: params,
      responseType: 'blob'
    });
  }

  /**
   * 
   */
  static importOrdersFromExcel(data) {
    return axios.post(process.env.VUE_APP_API + '/transactions/excel-import-operations', data);
  }

  /**
   * 
   */
  static getStatusOperationExcelUser(id) {
    return axios.get(process.env.VUE_APP_API + `/transactions/status-order-excel/${id}`)
  }

  /**
    * 
   */
  static transactionsForPay(ids) {
    return axios.get(process.env.VUE_APP_API + `/transactions/for-pay`, { params: { ids } })
  }

  /**
   * @description get a element by id  
   * @param { id } id point 
   */
      static createCharge(data, id) {
      return axios.post(process.env.VUE_APP_API + '/transactions/createCharge/' + id, data);
  }


  /**
   * 
   */
   static deleteCharge(id){
    return axios.post(process.env.VUE_APP_API + '/transactions/deleteCharge/' + id)
  }

}