var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-1"},[_c('validation-observer',{ref:"changeOperationForm"},[(_vm.form)?_c('b-form',{on:{"submit":_vm.onSubmit}},[_c('b-form-group',{attrs:{"label":"Email","description":"Email de destinatario"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","label":"text","disabled":_vm.formDisabled,"name":"recipient_email","state":errors.length > 0 ? false : null},model:{value:(_vm.form.recipient_email),callback:function ($$v) {_vm.$set(_vm.form, "recipient_email", $$v)},expression:"form.recipient_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,996890428)})],1),_c('b-form-group',{attrs:{"label":"Nombre","description":"Nombre de destinatario"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","label":"text","disabled":_vm.formDisabled,"name":"recipient_name","state":errors.length > 0 ? false : null},model:{value:(_vm.form.recipient_name),callback:function ($$v) {_vm.$set(_vm.form, "recipient_name", $$v)},expression:"form.recipient_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3904695996)})],1),_c('b-form-group',{attrs:{"label":"Apellido","description":"Apellido de destinatario"}},[_c('validation-provider',{attrs:{"name":"Apellido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","label":"text","disabled":_vm.formDisabled,"name":"recipient_last_name","state":errors.length > 0 ? false : null},model:{value:(_vm.form.recipient_last_name),callback:function ($$v) {_vm.$set(_vm.form, "recipient_last_name", $$v)},expression:"form.recipient_last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4149819068)})],1),_c('b-form-group',{attrs:{"label":"Telefono","description":"Telefono de destinatario"}},[_c('validation-provider',{attrs:{"name":"Telefono","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","label":"text","disabled":_vm.formDisabled,"name":"recipient_phone","state":errors.length > 0 ? false : null},model:{value:(_vm.form.recipient_phone),callback:function ($$v) {_vm.$set(_vm.form, "recipient_phone", $$v)},expression:"form.recipient_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2809343804)})],1),_c('h4',{staticClass:"title my-1 pt-2"},[_vm._v("A DONDE LO VAMOS A BUSCAR ?")]),_c('b-form-group',{attrs:{"label":"Dirección","description":"Dirección"}},[_c('b-form-textarea',{attrs:{"disabled":true},model:{value:(_vm.collectionAddresses),callback:function ($$v) {_vm.collectionAddresses=$$v},expression:"collectionAddresses"}})],1),_c('h4',{staticClass:"title my-1 pt-2"},[_vm._v("A DONDE LO VAMOS A LLEVAR ?")]),_c('b-form-group',{attrs:{"label":"Dirección","description":"Dirección"}},[_c('b-form-textarea',{attrs:{"disabled":true},model:{value:(_vm.retirementAddresses),callback:function ($$v) {_vm.retirementAddresses=$$v},expression:"retirementAddresses"}})],1),_c('h4',{staticClass:"title my-1 pt-2"},[_vm._v("QUE VAMOS A LLEVAR ?")]),_c('b-form-group',{attrs:{"label":"Producto","description":"Descripcion del producto a enviar"}},[_c('validation-provider',{attrs:{"name":"Producto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","disabled":true,"name":"product","state":errors.length > 0 ? false : null},model:{value:(_vm.form.product),callback:function ($$v) {_vm.$set(_vm.form, "product", $$v)},expression:"form.product"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,940742209)})],1),_c('b-form-group',{attrs:{"label":"Peso","description":"Peso del producto a enviar"}},[_c('validation-provider',{attrs:{"name":"Peso"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"number","label":"text","disabled":true,"name":"weight","min":"0","state":errors.length > 0 ? false : null},model:{value:(_vm.form.weight),callback:function ($$v) {_vm.$set(_vm.form, "weight", $$v)},expression:"form.weight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('span',{staticClass:"help-input"},[_vm._v("Minimo 1 KG")])]}}],null,false,844047185)})],1),_c('b-form-group',{attrs:{"label":"Dimensiones","description":"Dimensiones del producto a enviar"}},[_c('validation-provider',{attrs:{"name":"Dimensiones","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","maxlength":"100","disabled":true,"name":"dimensions","placeholder":"33x44x55","state":errors.length > 0 ? false : null},model:{value:(_vm.form.dimensions),callback:function ($$v) {_vm.$set(_vm.form, "dimensions", $$v)},expression:"form.dimensions"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1398207227)})],1),_c('b-form-group',{attrs:{"label":"Nota","description":"¿Algo adicional que debamos saber?"}},[_c('validation-provider',{attrs:{"name":"Nota"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"size":"sm","type":"text","maxlength":"700","disabled":_vm.formDisabled,"name":"note","state":errors.length > 0 ? false : null},model:{value:(_vm.form.note),callback:function ($$v) {_vm.$set(_vm.form, "note", $$v)},expression:"form.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2351359140)})],1),_c('b-form-group',{attrs:{"label":"Monto","description":"Monto a pagar por el envio"}},[_c('validation-provider',{attrs:{"name":"Monto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","readonly":"","disabled":false,"name":"amount","state":errors.length > 0 ? false : null},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4069575002)})],1),_c('div',{staticClass:"row"},[(!_vm.formDisabled)?_c('div',{staticClass:"col-12"},[_c('b-button',{ref:"submitButton",staticClass:"mr-1 float-right",attrs:{"variant":"primary","type":"submit"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"15","icon":"SaveIcon"}}),_c('span',{staticStyle:{"font-size":"15px"}},[_vm._v(" Guardar ")])],1)],1):_c('div',{staticClass:"col-12"},[(_vm.user.type === 'pre-pago')?_c('b-alert',{attrs:{"show":"","variant":"info"}},[_vm._v(" Para continuar con el proceso de envío, es necesario que la orden sea abonada. ")]):_vm._e(),(_vm.user.type === 'pre-pago')?_c('b-button',{staticClass:"mr-1 float-right",attrs:{"variant":"success","type":"button"},on:{"click":_vm.onPay}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"15","icon":"CreditCardIcon"}}),_c('span',{staticStyle:{"font-size":"15px"}},[_vm._v(" Pagar ")])],1):_vm._e(),_c('router-link',{staticClass:"btn btn-primary mr-1 float-right text-white",staticStyle:{"background":"#f68d1e","border-color":"#f68d1e"},attrs:{"to":"/dashboard/operations"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"15","icon":"AlignJustifyIcon"}}),_c('span',{staticStyle:{"font-size":"15px"}},[_vm._v(" Operaciones ")])],1)],1)])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }